// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-templates-book-list-tsx": () => import("./../../../src/templates/book-list.tsx" /* webpackChunkName: "component---src-templates-book-list-tsx" */),
  "component---src-templates-book-page-tsx": () => import("./../../../src/templates/book-page.tsx" /* webpackChunkName: "component---src-templates-book-page-tsx" */),
  "component---src-templates-list-tsx": () => import("./../../../src/templates/list.tsx" /* webpackChunkName: "component---src-templates-list-tsx" */),
  "component---src-templates-page-tsx": () => import("./../../../src/templates/page.tsx" /* webpackChunkName: "component---src-templates-page-tsx" */),
  "component---src-templates-post-tsx": () => import("./../../../src/templates/post.tsx" /* webpackChunkName: "component---src-templates-post-tsx" */),
  "component---src-templates-tags-tsx": () => import("./../../../src/templates/tags.tsx" /* webpackChunkName: "component---src-templates-tags-tsx" */)
}

